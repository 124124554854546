import { Component, OnInit } from '@angular/core';
import { CompaniesService, AxesService, FichesActionsService } from '../../../services/services';
import { FichesActions } from '../../../models/fichesActions';
import { Router, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/services/helpers/helper.service';

@Component({
  selector: 'app-detail-fiches-actions',
  templateUrl: './detail-fiches-actions.component.html',
  styleUrls: ['./detail-fiches-actions.component.scss']
})

export class DetailFichesActionsComponent implements OnInit {

  // déclaration et initialisation des varibles
  public companies: any[] = [];
  public ficheActions: any[] = [];
  public axes: any[] = [];
  public ficheAction: FichesActions;
  public company_id: string = null;
  public Editor = ClassicEditor;
  public config: any;

  constructor(public companiesService: CompaniesService,
    public serviceAxe: AxesService,
    private service: FichesActionsService,
    private router: Router,
    private helperService: HelperService,
    private route: ActivatedRoute, private toastr: ToastrService) {

    if (sessionStorage.getItem('admKYU') == '1') {
      this.router.navigate(['/users']);
    }

    this.ficheAction = new FichesActions();

    // on récupère l'id passé en paramètre
    this.route.params.subscribe(params => {
      this.ficheAction.id = params['id'];
    });

    this.config = '{ toolbar: [ \'heading\', \'|\', \'bold\', \'italic\' ,\'back\',\'next\'] }';
  }

  ngOnInit() {
    //pour pointer en haut de la page
    window.scroll(0, 0);
    this.getCompanyId();
    this.getAxes();
    if (this.ficheAction.id > 0) {
      this.load();
    }
  }

  // pour obtenir l'id de company
  getCompanyId() {
    this.companiesService.getCompanies().subscribe(r => {
      let resp = r.json();
      this.companies = resp.data;
    });
  }

  // pour charger les axes liés à la fiche action
  getAxes() {
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.serviceAxe.getAxes(this.company_id).subscribe(r => {
      let resp = r.json();
      this.axes = resp.data;
    });
  }

  // pour sauvegarder les fiches actions
  save() {
    this.ficheAction.intCompany = this.helperService.getCompanyIdForSearch();
    if (this.ficheAction.benefices == '' || this.ficheAction.description == ''
      || this.ficheAction.impacts == '') {
      this.toastr.info('Veuillez renseigner  les champs obligatoires (*) !');
    } else {
      this.service.saveFicheAction(this.ficheAction).subscribe(r => {
        let resp = r.json();
        this.ficheActions = resp.data;
        this.toastr.success('Ajouté avec succès!!!');
      });
    }
  }

  //pour aller à la page fiche actions après ajpout
  goToFicheAction() {
    this.router.navigateByUrl('/fiches-actions');
  }

  // pour charger les datas à modifier
  load() {
    this.service.load(this.ficheAction.id).subscribe(r => {
      let resp = r.json();
      let obj: any = resp.data;

      this.ficheAction.name = obj.name;
      this.ficheAction.note = obj.note;
      this.ficheAction.description = obj.description;
      this.ficheAction.benefices = obj.benefices;
      this.ficheAction.impacts = obj.impacts;
      this.ficheAction.company_id = obj.company_id;
      this.ficheAction.axe_id = obj.axe_id;
    });
  }
}
