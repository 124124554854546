import { Component, OnInit } from '@angular/core';
import { CalculModesService, AxesService, DomainesService, AxeVersionsService, QuestionsService } from '../../../../services/services';
import { Questions } from '../../../../models/questions';
import { HelperService } from '../../../../services/helpers/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debug } from 'util';

@Component({
  selector: 'app-detail-question',
  templateUrl: './detail-question.component.html',
  styleUrls: ['./detail-question.component.scss']
})
export class DetailQuestionComponent implements OnInit {
  //déclaration et initialisation des variable
  public question: any;
  public questions: Questions;
  public calcule_modes: any;
  public axes: any;
  public domaines: any;
  public questionnaireId: any;

  constructor(
    private service: CalculModesService,
    public domaineService: DomainesService,
    public router: ActivatedRoute,
    public route: Router,
    public serviceAxeVersions: AxeVersionsService,
    public serviceQuestion: QuestionsService,
     private toster: ToastrService) {
    if (sessionStorage.getItem('admKYU') == '1') {
        this.route.navigate(['/users']);
      }
    this.questions = new Questions;
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    //on récupère le paramètre passé 
    this.router.params.subscribe(params => {
      this.questions.questionnair_version_id = params['idQuestionnaireVersion'];
      this.questionnaireId = params['idQuestionnaire'];
    });

    //recuperer toutes les calcule mode
    this.getCalculeMode();
    this.getAllAxesByQuestionnairVersionId();

  }

  //pour recuperer toutes les calcule mode
  getCalculeMode() {
    this.service.getCalculeModes().subscribe((resp) => {
      this.calcule_modes = resp.json();
    });
  }

  // pour obtenir tous les axes
  getAllAxesByQuestionnairVersionId() {
    this.serviceAxeVersions.getAllQuetionnaireVersionsAxe(this.questions.questionnair_version_id).subscribe(r => {
      this.axes = r.json();
    });
  }

  //pour obtenir tous les axes associé a un domaine
  getDomainesByAxe(axe_id) {
    this.domaineService.getDomainesByAxeId(axe_id).subscribe(r => {
      this.domaines = r.json();
    })
  }

  //quand la changement de l'axe
  onChange(axe_id) {
    this.getDomainesByAxe(axe_id);
  }

  //on ajoute la question
  saveQuestion() {

    if (this.questions.question != ''
      && this.questions.description != ''
      && this.questions.axe != null
      && this.questions.domaine != ""
      && this.questions.calcule_mode_id != '0'
      && this.questions.ordre != "") {
      this.serviceQuestion.saveQuestion(this.questions).subscribe(r => {

        if (r.json()== "1") {
          this.route.navigate(['/questionnaires/versions/questions/', this.questionnaireId, this.questions.questionnair_version_id]);
        }
      })
    } else {
      this.toster.info('Veuillez remplir tous les champs obligatoires (*) !');
    }
  }

  //aller sur question
  GoToQuestion() {
    this.route.navigate(['/questionnaires/versions/questions/', this.questionnaireId, this.questions.questionnair_version_id]);
  }
}
