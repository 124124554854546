import { Component, OnInit } from '@angular/core';
import { FichesActions } from '../../models/fichesActions';
import { State } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { FichesActionsService, AxesService } from '../../services/services';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fiches-actions',
  templateUrl: './fiches-actions.component.html',
  styleUrls: ['./fiches-actions.component.scss']
})

export class FichesActionsComponent implements OnInit {
  //déclaration et initialisation des variables 
  public fichesActions: FichesActions;
  public gridData: any[] = [];
  public company_id: string = null;
  public axes: any[] = [];
  public axe_id: string = '';
  public ficheAction_id: string = '';
  public ficheAction_name: string = '';
  public gridView: GridDataResult;
  public gridState: State = {
    sort: [{ 'field': 'axe_name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(private router: Router,
    private service: FichesActionsService,
    private helperService: HelperService,
    private axeService: AxesService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal) {
      if (sessionStorage.getItem('admKYU') == '1') {
        this.router.navigate(['/users']);
      }

    this.fichesActions = new FichesActions;
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    //appeler la methode qui recuper les fiche axe
    this.search();

    //recuperer les axes par id company
    this.getAxes();
  }

  //pour le changement du gid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  //aller pour ajouter un fiche action
  GoToaddFicheAction() {
    this.router.navigate(['fiches-actions/addFicheAction']);
  }

  // pour obtenir tous les axes
  getAxes() {
    this.company_id = this.helperService.getCompanyIdForSearch();

    this.axeService.getAxes(this.company_id).subscribe(r => {
      let resp = r.json();
      this.axes = resp.data;
    });
  }

  //pour la recherche
  search() {
    this.spinner.show();
    this.company_id = this.helperService.getCompanyIdForSearch();

    this.service.getSearchFichesActions(this.fichesActions.name,
      this.fichesActions.description,
      this.axe_id, this.company_id, this.fichesActions.benefices,
      this.fichesActions.impacts, this.fichesActions.note, this.gridState).subscribe(r => {
        let resp = r.json();
        this.gridData = resp.data;

        this.gridView = {
          data: resp.data,
          total: resp.total
        };

        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
      });
  }

  // aller pour la modification des fiches actions
  updateFicheAction(item: any) {
    this.router.navigateByUrl('/fiches-actions/updateFicheAction/' + item.id);
  }

  //la methode qui recupere les fiches de  l'axe
  getFichesActions() {
    this.service.getFichesActions().subscribe(r => {
      let resp = r.json();
      this.gridData = resp.data;
    });
  }

  //les methodes de model
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.ficheAction_id = dataItem.id;
    this.ficheAction_name = dataItem.name;
  }

  //supprimer un fiche action
  deleteFicheAction() {
    this.service.deleteFicheAction(this.ficheAction_id).subscribe(r => {
      let resp = r.json();

      if (resp == '1') {
        this.search();
        this.modalService.dismissAll();
      }
    });
  }
}
