import { ApiService } from './api/api.service';
import { AuthService } from './authentification/auth.service';
import { CompaniesService } from './companies/companies.service';
import { AxesService } from './axes/axes.service';
import { DomainesService } from './domaines/domaines.service';
import { TemoignagesService } from './temoignages/temoignages.service';
import { UsersService } from './users/users.service';
import { FichesActionsService } from './fiches-actions/fiches-actions.service';
import { QuestionnaireVersionsService } from './questionnaire-versions/questionnaire-versions.service';
import { AxeVersionsService } from './axe-versions/axe-versions.service';
import { QuestionsService } from './questions/questions.service';
import { CalculModesService } from './calcul-modes/calcul-modes.service';
import { ReponsesService } from './reponses/reponses.service';
import { QuestionnairesService } from './questionnaires/questionnaires.service';

export {
  ApiService,
  AuthService,
  CompaniesService,
  AxesService,
  DomainesService,
  TemoignagesService,
  UsersService,
  FichesActionsService,
  QuestionnaireVersionsService,
  AxeVersionsService,
  QuestionsService,
  CalculModesService,
  ReponsesService,
  QuestionnairesService
};
