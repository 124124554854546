import { Injectable } from '@angular/core';
import { ApiService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AxeVersionsService {
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // pour obtenir les axes versions 
  getAllQuetionnaireVersionsAxe(questionnaire_version_id: any) {
    let obj = {
      questionnaire_version_id: questionnaire_version_id,
      user_id: this.user_id
    }
    return this.api.post("questionnaireVersionsAxe", obj);
  }

  // pour rechercher les axes versions 
  search(questionnaire_id, axe_name, orderBy, company_id, gridState) {
    const obj = {
      questionnaire_id: questionnaire_id,
      axe_name: axe_name,
      orderBy: orderBy,
      company_id: company_id,
      gridState: gridState,
      user_id: this.user_id
    };
    return this.api.post('searchQuestionnaireVersionsAxe', obj);
  }

  // pour sauvegarder les axe versions 
  public save(data: any, isNew?: boolean) {
    data['user_id'] = this.user_id;
    return this.api.post('saveQuestionnaireVersionsAxe', data);
  }

  // pour supprimer les axes aversions 
  deleteQuestionnaireVersionsAxe(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };
    return this.api.post('deleteQuestionnaireVersionsAxe', obj);
  }

}
