import { Injectable } from '@angular/core';
import { ApiService } from '../services';

const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

@Injectable({
  providedIn: 'root'
})

export class CompaniesService {
  //déclaration et initialisation des variables
  private data: any[] = [];
  public user_id;
  
  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // rechercher companies par nom,description, etat d'activation et gridState
  search(name, description, active, gridState) {
    const obj = {
      name: name,
      description: description,
      active: active,
      gridState: gridState,
      user_id: this.user_id
    };

    return this.api.post('searchCompanies', obj);
  }

  // suprimer company
  deleteCompanies(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteCompanie', obj);
  }

  // sauvegarder une companies
  public save(data: any, isNew?: boolean) {
    data['user_id'] = this.user_id;
    return this.api.post('saveCompanies', data);
  }

  //Company URL
  public getCompanyURL(company_id) {
    const obj = {
      company_id: company_id,
      user_id: this.user_id
    };

    return this.api.post('getCompanyUrl', obj);
  }

  //saveCompanyUrl
  public saveCompanyUrl(data: any, isNew?: boolean) {
    data['user_id'] = this.user_id;
    return this.api.post('saveCompanyUrl', data);
  }

  // supprimer CompanyUrl
  public deleteCompanyUrl(id?: boolean) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteCompanyUrl', obj);
  }

  // recuperer tous les companies
  public getCompanies() {
    const obj = {
      user_id: this.user_id
    };

    return this.api.post('getAllCompany', obj);
  }
}
