import { Injectable } from '@angular/core';
import { ApiService } from '../services';
import { QuestionnaireVersion } from '../../models/questionnaireVersion';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireVersionsService {
  //déclaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
}

  // envoi toutes les questionnaire versions
  getAllQuetionnaireVersions(questionnaire_id: any, gridState) {
    let obj = {
      questionnaire_id: questionnaire_id,
      gridState: gridState,
      user_id: this.user_id
    }
    return this.api.post("getQuestionnaireVersions", obj);
  }

  //rechercher un questionnaire version
  searchQuetionnaireVersions(questionnaire_id: any, qnrVer: QuestionnaireVersion, gridState: any) {
    let obj = {
      questionnaire_id: questionnaire_id,
      version: qnrVer.version,
      startDate: qnrVer.startDate,
      endDate: qnrVer.endDate,
      gridState: gridState,
      user_id: this.user_id
    }

    return this.api.post("getQuestionnaireVersions", obj);
  }

  // activer question version
  anableQuestionnaireVersion(dataItem_id: any) {
    let obj = {
      dataItem_id: dataItem_id,
      user_id: this.user_id
    }

    return this.api.post("anableQuestionnaireVersion", obj);
  }

  // dupliquer questionnaire version
  duplicateQuestionnaireVersions(questionnaire_id: any, questionnaire_version_id: any) {
    let obj = {
      questionnaire_id: questionnaire_id,
      questionnaire_version_id: questionnaire_version_id,
      user_id: this.user_id
    }
    return this.api.post("duplicateQuestionnaireVersions", obj);
  }

  //sauvgarder questionnaire version
  save(formGroup, active) {

    let obj = {
      questionnaireVersionId: formGroup.value.questionnaireVersionId,
      description: formGroup.value.description,
      questionnaire_id: formGroup.value.questionnaire_id,
      start_date: formGroup.value.start_date,
      end_date: formGroup.value.end_date,
      active: active,
      user_id: this.user_id
    }
    console.table(obj);

    return this.api.post("saveQuestionnaireVersion", obj);
  }

  //supprimer questionniare version id
  deleteQuestionnaireVersion(id) {
    let obj = {
      questionnaireVersionId: id,
      user_id: this.user_id
    }
    return this.api.post("deleteQuestionnaireVersion", obj);
  }
}
