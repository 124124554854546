import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  LoginComponent,
  CompaniesComponent,
  AxesComponent,
  DomainesComponent,
  TemoignagesComponent,
  DetailTemoignageComponent,
  UsersComponent,
  FichesActionsComponent,
  DetailFichesActionsComponent,
  ConfirmationPasswordComponent,
  ChangePasswordComponent,
  QuestionnaireVersionsComponent,
  QuestionsComponent,
  AxeVersionsComponent,
  DetailQuestionComponent,
  ReponsesComponent,
  QuestionnairesComponent,
} from './pages/pages';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'confirmationPassword/:id',
    component: ConfirmationPasswordComponent
  },
  {
    path: 'users/change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'companies',
    component: CompaniesComponent,
  },
  {
    path: 'axes',
    component: AxesComponent,
  },
  {
    path: 'domaines',
    component: DomainesComponent,
  },
  {
    path: 'questionnaire-versions/:id',
    component: QuestionnaireVersionsComponent,
  },
  {
    path: 'questionnaires',
    children: [
      {
        path: '',
        component: QuestionnairesComponent,
      },
      {
        path: 'versions/:idQuestionnaire',
        component: QuestionnaireVersionsComponent,
      },
      {
        path: 'versions/questions/add-question/:idQuestionnaire/:idQuestionnaireVersion',
        component: DetailQuestionComponent,
      },
      {
        path: 'versions/axe-versions/:idQuestionnaire/:idQuestionnaireVersion',
        component: AxeVersionsComponent,
      },
      {
        path: 'versions/questions/:idQuestionnaire/:idQuestionnaireVersion',
        component: QuestionsComponent,
      },
      {
        path: 'versions/question/reponse/:idQuestionnaire/:idQuestionnaireVersion/:idQuestion',
        component: ReponsesComponent,
      }
    ]
  },
  {
    path: 'temoignages',
    children: [
      {
        path: '',
        component: TemoignagesComponent
      },
      {
        path: 'addTemoignage',
        component: DetailTemoignageComponent
      },
      {
        path: 'addTemoignage/:id',
        component: DetailTemoignageComponent
      }
    ]
  },
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'fiches-actions',
    children: [
      {
        path: '',
        component: FichesActionsComponent
      },
      {
        path: 'addFicheAction',
        component: DetailFichesActionsComponent
      }, {
        path: 'updateFicheAction/:id',
        component: DetailFichesActionsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
