import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { TemoignagesService, CompaniesService, AxesService } from 'src/app/services/services';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-temoignages',
  templateUrl: './temoignages.component.html',
  styleUrls: ['./temoignages.component.scss']
})

export class TemoignagesComponent implements OnInit {
  // déclaration et initialisation des varibles
  public name: string = null;
  public description: string = null;
  public client: string = null;
  public gridData: any[] = [];
  public companies: any[] = [];
  public company_id: string = null;
  public axes: any[] = [];
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public view: Observable<GridDataResult>;
  public gridView: GridDataResult;
  public formGroup: FormGroup;
  public gridState: State = {
    sort: [{ 'field': 'name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(private temoignageService: TemoignagesService,
    private companyService: CompaniesService,
    private serviceAxe: AxesService,
    private helperService: HelperService,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService) {
    if (sessionStorage.getItem('admKYU') == '1') {
      this.router.navigate(['/users']);
    }
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    // pour effectuer la recherche
    this.search();

    //pour charger les axes 
    this.getAxes();
  }

  // pour charger les témoignages
  getTemoignages() {
    this.temoignageService.getTemoignages().subscribe(r => {
      let resp = r.json();
      this.gridData = resp.data;
    });
  }

  //pour charger les companies
  getCompanies() {
    this.companyService.getCompanies().subscribe((resp: any) => {
      let r = resp.json();
      this.companies = r.data;
    });
  }

  //pour charger les axes
  getAxes() {
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.serviceAxe.getAxes(this.company_id).subscribe(r => {
      let resp = r.json();
      this.axes = resp.data;
    });
  }

  // pour effectuer la recherche
  search() {
    this.spinner.show();
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.temoignageService.search(this.name, this.description, this.client, this.company_id, this.gridState).subscribe(r => {
      let resp = r.json();
      this.gridData = resp.data;

      this.gridView = {
        data: resp.data,
        total: resp.total
      };
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }

  // pour la suppression des témoignages
  deleteTemoignages() {
    this.temoignageService.deleteTemoignage(this.dataItem_id).subscribe(r => {
      let resp = r.json();

      if (resp == 1) {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  // pour le controle du changement du grid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // pour la suppression d'une ligne du grid
  public removeHandler({ dataItem }) {
    this.temoignageService.deleteTemoignage(dataItem.id).subscribe(r => {
      let resp = r.json();
      this.search();
    });
  }

  // pour aller à la page d'ajout
  GoToaddTemoignage() {
    this.router.navigateByUrl('temoignages/addTemoignage');
  }

  //Pour modifier un témoignage
  updateTemoignage(item) {
    this.router.navigateByUrl('temoignages/addTemoignage/' + item.id);
  }

  //les methodes de model
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });

    this.dataItem_id = dataItem.id;
    this.dataItem_name = dataItem.name;
  }
}
