import { HelperService } from './../../../services/helpers/helper.service';
import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ReponsesService, QuestionsService } from 'src/app/services/services';
import { Reponse } from 'src/app/models/responses';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reponses',
  templateUrl: './reponses.component.html',
  styleUrls: ['./reponses.component.scss']
})
export class ReponsesComponent implements OnInit {
  // déclaration et initialisation des varibles
  public questionId: number = null;
  public gridData: any[] = [];
  public notes: any[] = [];
  public dataItemId: any = '';
  public dataItemName: any = '';
  public name: string = null;
  public description: string = null;
  public questionName: string = null;
  public note: number = null;
  public reponseId: number = null;
  public companyId: string = null;
  public selectedValueNote: number;
  public questionnaireVersion = '';
  public questionnaireId = '';
  public view: Observable<GridDataResult>;
  public gridView: GridDataResult;
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public gridState: State = {
    sort: [{ 'field': 'note', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(
    private helperService: HelperService,
    private modalService: NgbModal,
    private reponseService: ReponsesService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService) {
    if (sessionStorage.getItem('admKYU') == '1') {
      this.router.navigate(['/users']);
    }

    // on récupère le paramètre passé
    this.route.params.subscribe((params) => {
      this.questionnaireId = params['idQuestionnaire'];
      this.questionnaireVersion = params['idQuestionnaireVersion'];
      this.questionId = params['idQuestion'];
    });

    // on initialise les notes pour la ddl
    this.notes = [
      {
        'id': 1,
        "note": "-1"
      },

      {
        'id': 2,
        "note": "0"
      },
      {
        'id': 3,
        "note": "1"
      },
      {
        'id': 4,
        "note": "2"
      },
      {
        'id': 5,
        "note": "3"
      },
      {
        'id': 6,
        "note": "4"
      },
    ];
    this.selectedValueNote = 0;
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    this.getReponseQuestionById();
    this.getQuestionName();
  }

  // pour obtenir les réponses d'une question donnée
  getReponseQuestionById() {

    // on fait l'appel de l'api avec le paramètre récupéré
    this.reponseService.getReponseQuestionById(this.questionId).subscribe(r => {
      const resp = r.json();
      this.gridView = resp;
    });
  }

  // pour avoir le nom de la question
  getQuestionName() {
    this.reponseService.getQuestionNameById(this.questionId).subscribe(r => {
      const resp = r.json();
      this.questionName = resp[0];
    });
  }

  // pour effectuer la recherche sur les réponses
  search() {
    this.spinner.show();
    this.companyId = this.helperService.getCompanyIdForSearch();
    this.reponseService.search(this.questionId, this.name, this.description, this.note, this.companyId, this.gridState).subscribe(r => {
      const resp = r.json();
      this.gridData = resp.data;
      this.gridView = {
        data: resp.data,
        total: resp.total
      };
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }

  //quand l'etat est changé
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // pour l'ajout  d'une réponse
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      id: new FormControl(),
      questionId: new FormControl(''),
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      note: new FormControl(''),
    });

    sender.addRow(this.formGroup);
  }

  // pour la modification d'une réponse
  public editHandler({ sender, rowIndex, dataItem }) {

    this.selectedValueNote = dataItem.note;
    this.closeEditor(sender);
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      questionId: new FormControl(this.questionId),
      name: new FormControl(dataItem.name, Validators.required),
      description: new FormControl(dataItem.description),
      note: new FormControl(dataItem.note, Validators.required),
    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  // pour annuler la modification
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // pour la sauvegarde d'une réponse
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    formGroup.value.companyId = this.helperService.getCompanyIdForSearch();
    formGroup.value.questionId = this.questionId;
    formGroup.value.note = this.selectedValueNote;
    const reponse: Reponse = formGroup.value;

    this.reponseService.save(reponse, isNew).subscribe(r => {
      const resp = r.json();
    });
    this.getReponseQuestionById();
    sender.closeRow(rowIndex);
  }

  // pour la suppression d'une réponse
  removeHandler() {
    this.reponseService.deleteQuestion(this.dataItemId).subscribe(r => {
      const resp = r.json();
      if (resp == '1') {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  //pour la fermeture
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // pour la popup de confirmation de suppression
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItemId = dataItem.id;
    this.dataItemName = dataItem.name;
  }

  // retourner en arrière
  goToQuestion() {
    this.router.navigate(['/questionnaires/versions/questions/', this.questionnaireId, this.questionnaireVersion]);
  }
}
