import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit {
  //déclaration et initialisation des variables
  public user_name = '';
  public chemin_company_logo = 'logo.png';
  public app_name = '';

  constructor() {
    if (sessionStorage.getItem('user_name') != null && sessionStorage.getItem('user_name') != undefined) {
      this.user_name = sessionStorage.getItem('user_name');
      this.chemin_company_logo = sessionStorage.getItem('company_logo');
    }

    this.app_name = environment.app_name;
  }

  ngOnInit() {
  }

  //pour deconnecter
  deconnect() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('admKYU');
    sessionStorage.removeItem('company_id');
    sessionStorage.removeItem('company_logo');
    sessionStorage.removeItem('company_name');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('user_name');

    window.location.reload();
  }
}
