import { Component, OnInit, Input } from '@angular/core';
import { CompaniesService } from '../../../services/services';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { companyUrl } from '../../../models/companyUrl';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-urls',
  templateUrl: './company-urls.component.html',
  styleUrls: ['./company-urls.component.scss']
})

export class CompanyUrlsComponent implements OnInit {
  //déclaration et initialisation des variables
  @Input() comapany_id: number;
  public view: Observable<GridDataResult>;
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public obj: companyUrl;
  public dataItem_id: any;
  public dataItem_url: any;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };

  constructor(private companiesService: CompaniesService, private modalService: NgbModal) {

  }

  ngOnInit() {
    //pour pointer on haut de la page 
    window.scroll(0, 0);
    this.getCompanyURL();
  }

  //pour récupèrer l'url d'un companie
  getCompanyURL() {
    this.companiesService.getCompanyURL(this.comapany_id).subscribe(r => {
      let resp = r.json();

      this.view = resp.data;
    });
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.getCompanyURL();
  }

  //pour ajouter une ligne
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      'id': new FormControl(),
      'company_id': new FormControl(this.comapany_id, Validators.required),
      'url': new FormControl()
    });

    sender.addRow(this.formGroup);
  }

  //pour modifier une ligne
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      'id': new FormControl(dataItem.id),
      'company_id': new FormControl(dataItem.company_id, Validators.required),
      'url': new FormControl(dataItem.url)

    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  //pour annuler la modif ou l'ajout d'une ligne
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  //pour enregistrer une ligne
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const company_url: companyUrl = formGroup.value;

    this.companiesService.saveCompanyUrl(company_url, isNew).subscribe(r => {
      let resp = r.json();
    });

    this.getCompanyURL();
    sender.closeRow(rowIndex);
  }

  //pour suprimer une ligne
  public removeHandler() {
    this.companiesService.deleteCompanyUrl(this.dataItem_id).subscribe(r => {
      let resp = r.json();
      if (resp.result == 1) {
        alert();
        this.modalService.dismissAll();
      }
      this.getCompanyURL();
    });
  }

  //pour la fermeture
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //pour ouvrir le message de confirmation
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.id;
    this.dataItem_url = dataItem.url;
  }
}
