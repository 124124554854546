export class Questions {
  public question?: string;
  public description?: string;
  public axe?: string;
  public domaine?: string;
  public calcule_mode?: string;
  public calcule_mode_id?: string;
  public ordre?: string;
  public company_id?: string;
  public questionnair_version_id?: string;

  constructor() {
    this.question = "";
    this.description = "";
    this.axe = null;
    this.domaine = "";
    this.calcule_mode = null;
    this.calcule_mode_id = '';
    this.ordre = "";
    this.company_id = "";
    this.questionnair_version_id = "";
  }
}
