import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../../../services/users/users.service';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-confirmation-password',
  templateUrl: './confirmation-password.component.html',
  styleUrls: ['./confirmation-password.component.scss']
})
export class ConfirmationPasswordComponent implements OnInit {
  //déclaration et initialisation des variables
  public id: string = "";

  constructor(private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService) {

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

  }

  ngOnInit() {
    //on point en haut de la page 
    window.scroll(0, 0);

    this.confirmationPassword();
  }
  
  // confirm changement password 
  confirmationPassword() {
    this.usersService.confirmationPassword(this.id).subscribe(r => {
      let resp = r.json();

      if (resp.result != "") {
        this.router.navigate(["/login"]);
      }
    });
  }
}
