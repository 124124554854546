import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule, Http } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './templates/header/header.component';
import { FooterComponent } from './templates/footer/footer.component';
import { SidebarComponent } from './templates/sidebar/sidebar.component';

import { TopbarComponent } from './templates/topbar/topbar.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';


import {
  LoginComponent,
  CompaniesComponent,
  AxesComponent,
  CompanyUrlsComponent,
  UsersComponent,
  FichesActionsComponent,
  DomainesComponent,
  TemoignagesComponent,
  DetailTemoignageComponent,
  DetailFichesActionsComponent,
  ConfirmationPasswordComponent,
  ChangePasswordComponent,
  QuestionnaireVersionsComponent,
  QuestionsComponent,
  AxeVersionsComponent,
  DetailQuestionComponent,
  ReponsesComponent,
  QuestionnairesComponent
} from './pages/pages';

import {
  ApiService,
  AuthService,
  AxesService,
  TemoignagesService,
  DomainesService,
  UsersService,
  QuestionnaireVersionsService,
  QuestionsService,
  CalculModesService,
  AxeVersionsService,
  ReponsesService,
  QuestionnairesService
} from './services/services';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CompaniesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    TopbarComponent,
    AxesComponent,
    CompanyUrlsComponent,
    DomainesComponent,
    TemoignagesComponent,
    DetailTemoignageComponent,
    CompanyUrlsComponent,
    UsersComponent,
    FichesActionsComponent,
    DetailFichesActionsComponent,
    ConfirmationPasswordComponent,
    QuestionnaireVersionsComponent,
    ChangePasswordComponent,
    QuestionsComponent,
    AxeVersionsComponent,
    DetailQuestionComponent,
    ReponsesComponent,
    QuestionnairesComponent,

  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GridModule,
    DropDownsModule,
    NgbModule,
    CKEditorModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule,
    DateInputsModule
  ],
  providers: [
    HttpModule,
    ApiService,
    AuthService,
    AxesService,
    TemoignagesService,
    DomainesService,
    UsersService,
    QuestionnaireVersionsService,
    QuestionsService,
    CalculModesService,
    AxeVersionsService,
    ReponsesService,
    QuestionnairesService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
