import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams, RequestMethod, ResponseContentType } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class ApiService {
    prefix = '/api';
    public userToken = '';

    constructor(public http: Http) {
        this.userToken = sessionStorage.getItem('token');
    }

    _formatError(err) {
        if (err.body && err.body.errors) {
            err.body.error = '';

            for (let k in err.body.errors) {
                err.body.error += '\n' + k + ':' + err.body.errors[k];
            }

            return Observable.throw({ message: err.body.error, code: err.body.code || err.statusCode });
        }

        return Observable.throw({
            message: err.message || err.body || err,
            code: err.code || err.statusCode
        });
    }

    // ajoute des headers dans le requestion
    createAuthorizationHeader(headers: Headers) {
        this.userToken = sessionStorage.getItem('token');

        if (headers && this.userToken) {
            headers.append('Authorization', 'Bearer ' + this.userToken);
            headers.append('company_token', environment.company_token);
        }
    }

    // 
    generateURL(endpoint, params, withToken = false): string {
        if (withToken) endpoint = '';

        let paramsStr = '';

        if (params) {
            paramsStr = Object.keys(params).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
            }).join('&');

            if (endpoint.indexOf('?') === -1) {
                endpoint += '?' + paramsStr;
            } else {
                endpoint += '&' + paramsStr;
            }
        }

        endpoint = endpoint.replace(/\?$/, '');
        let url = this.prefix + '/' + endpoint.replace(/^\//, '');
        return url;
    }

    // recuperer un stream de file sous form de blob
    getExcelSave(endpoint: string, body: any) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this.http.post(this.prefix + '/' + endpoint, body, {
            method: RequestMethod.Post,
            responseType: ResponseContentType.Blob,
            headers: headers
        }).catch(err => {
            return this._formatError(err.json());
        });
    }

    // envoi une requete get
    get(endpoint: string, params?: any, options?: RequestOptions) {
        if (!options) {
            options = new RequestOptions();
        }

        options.headers = new Headers();
        this.createAuthorizationHeader(options.headers);

        // Support easy query params for GET requests
        if (params) {
            let p = new URLSearchParams();

            for (let k in params) {
                p.set(k, params[k]);
            }

            // Set the search field if we have params and don't already have
            // a search field set in options.
            options.search = !options.search && p || options.search;
        }

        return this.http.get(this.prefix + '/' + endpoint, options)
            .catch((err, caught) => {
                console.log(err);
                return this._formatError(err.json());
            });
    }

    // envoi une requete  post
    post(endpoint: string, body: any) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);

        return this.http.post(this.prefix + '/' + endpoint, body, {
            headers: headers
        }).catch(err => {

            return this._formatError(err.json());
        });
    }

    // envoi une requete put
    put(endpoint: string, body: any) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);

        return this.http.put(this.prefix + '/' + endpoint, body, {
            headers: headers
        }).catch((err, caught) => {
            console.log(err);
            return this._formatError(err.json());
        });
    }

    // envoi une requete delete
    delete(endpoint: string) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this.http.delete(this.prefix + '/' + endpoint, { headers: headers }).catch((err, caught) => {
            console.log(err);
            return this._formatError(err.json());
        });
    }

    // envoi une requete path 
    patch(endpoint: string, body: any) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);

        return this.http.put(this.prefix + '/' + endpoint, body, { headers: headers }).catch((err, caught) => {
            return this._formatError(err.json());
        });
        ;
    }
}
