import { Injectable } from '@angular/core';
import { ApiService } from '../services';

const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

@Injectable({
  providedIn: 'root'
})

export class AxesService {
  //déclaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // rechercher axe par nom, description, company_id et gridState
  search(name, description, company_id, gridState) {
    const obj = {
      name: name,
      description: description,
      company_id: company_id,
      gridState: gridState,
      user_id: this.user_id
    };

    return this.api.post('searchAxes', obj);
  }

  // recuperer tous les axes
  getAxes(company_id) {
    const obj = {
      company_id: company_id,
      user_id: this.user_id
    };

    return this.api.post('axes', obj);
  }

  // suprimer les axes
  deleteAxes(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteAxe', obj);
  }

  // sauvegarder axe
  public save(data: any, isNew?: boolean) {
    data['user_id']=  this.user_id;
    return this.api.post('saveAxes', data);
  }
}
