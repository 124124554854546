import { Injectable } from '@angular/core';
import { ApiService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class CalculModesService {
  public user_id;
  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // recuperer la methode de calcule mode
  getCalculeModes() {
    let obj = {
      user_id: this.user_id
    }
    return this.api.post("getCalculeModes", obj);
  }
}
