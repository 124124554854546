import { Component, OnInit } from '@angular/core';
import { AxesService, CompaniesService } from '../../services/services';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Axes } from '../../models/axes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../services/helpers/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-axes',
  templateUrl: './axes.component.html',
  styleUrls: ['./axes.component.scss']
})

export class AxesComponent implements OnInit {
  //déclaration et initialisation des variables
  public name: string = null;
  public description: string = null;
  public company_id: string = null;
  public gridData: any[] = [];
  public companies: any[] = [];
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public selectedValueCompany_id = 0;
  public view: Observable<GridDataResult>;
  public gridView: GridDataResult;

  public gridState: State = {
    sort: [{ 'field': 'name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };
  
  public formGroup: FormGroup;
  private editedRowIndex: number;

  constructor(private axeService: AxesService,
    private companyService: CompaniesService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.getAxes();
    this.search();
  }

  // recuperer les axes d'une company
  getAxes() {
    this.company_id = this.helperService.getCompanyIdForSearch();

    this.axeService.getAxes(this.company_id).subscribe(r => {
      let resp = r.json();
      this.gridData = resp.data;
    });
  }

  //pour la supression d'un column
  removeHandler(event) {}

  // recuperer les companies
  getCompanies() {
    this.companyService.getCompanies().subscribe((resp: any) => {
      let r = resp.json();
      this.companies = r.data;
    });
  }

  // recuperer les axes par leurs nom, descriptioncompany_id, company_id et gridState
  search() {
    this.spinner.show();
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.axeService.search(this.name, this.description, this.company_id, this.gridState).subscribe(r => {
      let resp = r.json();
      this.gridData = resp.data;

      this.gridView = {
        data: resp.data,
        total: resp.total
      };
      
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }

  // detection changement des donnes de grid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // ajouter un nouvelle ligne axe
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      'id': new FormControl(),
      'company_id': new FormControl(''),
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
    });

    sender.addRow(this.formGroup);
  }

  // ajoute une nouvelle ligne pour la modification
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      'id': new FormControl(dataItem.id),
      'company_id': new FormControl(dataItem.company_id),
      'name': new FormControl(dataItem.name, Validators.required),
      'description': new FormControl(dataItem.description, Validators.required),
    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  // annuler la minipulation d'une nouvelle enregistrement
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // sauvegarder les modification
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    this.company_id = this.helperService.getCompanyIdForSearch();
    formGroup.value.company_id = this.company_id;
    const axes: Axes = formGroup.value;

    this.axeService.save(axes, isNew).subscribe(r => {
      let resp = r.json();
    });

    this.search();
    sender.closeRow(rowIndex);
  }

  // suprimer une ligne
  deleteAxes() {
    this.axeService.deleteAxes(this.dataItem_id).subscribe(r => {
      let resp = r.json();

      if (resp == 1) {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  // fermer l'editeur qui permet d'ajouter une ligne
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //les methodes de model
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.id;
    this.dataItem_name = dataItem.name;
  }
}
