import { Component, OnInit } from '@angular/core';
import { QuestionsService, CalculModesService, DomainesService, AxeVersionsService } from '../../../services/services';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Questions } from 'src/app/models/questions';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})

export class QuestionsComponent implements OnInit {
  //declaration initialisation des variables
  public questionnaire_version_id: any = null;
  public questionnaire_id: any = null;
  public gridData: any[] = [];
  public company_id: string = null;
  public axe_id: string = '';
  public calcul_mode_id: string = null;
  public domaine_id: string = '';
  public name_question: string = null;
  public order_by: number = null;
  public question_description: string = null;
  public question_id: number = null;
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public questions: Questions;
  public selectedValueAxe_id = 0;
  public selectedValueDomaine_id = 0;
  public selectedValueCalculMode_id = 0;
  public calcule_modes: any[] = [];
  public axes: any[] = [];
  public domainesAxe: any[] = [];
  public domaines: any[] = [];
  public view: Observable<GridDataResult>;
  public gridView: GridDataResult;
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public dataItemName: any;
  public gridState: State = {
    sort: [{ 'field': 'order_by', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(
    private questionService: QuestionsService,
    private router: ActivatedRoute,
    private route: Router,
    private service: CalculModesService,
    public domaineService: DomainesService,
    public serviceAxeVersions: AxeVersionsService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService) {

    if (sessionStorage.getItem('admKYU') == '1') {
      this.route.navigate(['/users']);
    }
    
    // on récupère le paramètre passé
    this.router.params.subscribe(params => {
      this.questionnaire_version_id = params['idQuestionnaireVersion'];
      this.questionnaire_id = params['idQuestionnaire'];
    });

    this.questions = new Questions();
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    // recuperer toutes les calcule mode
    this.getCalculeMode();

    // pour obtenir tous les axes
    this.getAllAxesByQuestionnairVersionId();

    //on recupere les critere de recherche
    let critereQuestion = JSON.parse(sessionStorage.getItem("critereQuestion"));
    if (critereQuestion != null) {
      this.name_question = critereQuestion.question;
      this.axe_id = critereQuestion.axeId;
      this.domaine_id = critereQuestion.domaineId;
      this.questions.calcule_mode_id = critereQuestion.calculeMode;
      sessionStorage.removeItem("critereQuestion");
    }
    this.search();
  }

  //recherche question par name_question,axe_id,domaine_id,calcule_mode_id
  searchQuestion() {
    this.search();

    //garder les critere de recherche au session
    let critereQuestion = {
      question: this.name_question,
      axeId: this.axe_id,
      domaineId: this.domaine_id,
      calculeMode: this.questions.calcule_mode_id
    };
    sessionStorage.setItem("critereQuestion", JSON.stringify(critereQuestion));
  }

  // pour effectuer la recherche sur les  questions
  search() {
    this.spinner.show();
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.questionService.search(
      this.axe_id,
      this.questions.calcule_mode_id,
      this.domaine_id,
      this.name_question,
      this.company_id,
      this.order_by,
      this.question_description,
      this.gridState,
      this.questionnaire_version_id).subscribe(r => {
        const resp = r.json();
        this.gridData = resp.data;
        this.gridView = {
          data: resp.data,
          total: resp.total
        };
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
      });
  }

  // pour recuperer toutes les calcule mode
  getCalculeMode() {
    this.service.getCalculeModes().subscribe((resp) => {
      this.calcule_modes = resp.json();
    });
  }

  // pour obtenir tous les axes
  getAllAxesByQuestionnairVersionId() {
    this.serviceAxeVersions.getAllQuetionnaireVersionsAxe(this.questionnaire_version_id).subscribe(r => {
      this.axes = r.json();
    });
  }

  // pour obtenir tous les axes associé a un domaine
  getDomainesByAxe(axe_id) {
    this.domaineService.getDomainesByAxeId(axe_id).subscribe(r => {
      this.domainesAxe = r.json();
    });
  }

  // evenement de changement du list des event 
  onChange(axe_id) {
    this.getDomainesByAxe(axe_id);
  }

  // evenement de changement du list des event lie au kendo component
  selectionChange(event) {
    this.getDomainesByAxe(event.id);
  }

  // pour le changement des données du grid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }
  // aller à  l'ajout des questions
  addQuestion() {
    this.route.navigate(['/questionnaires/versions/questions/add-question', this.questionnaire_id, this.questionnaire_version_id]);
  }

  // pour l'ajout  d'une réponse
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      id: new FormControl(),
      axe_id: new FormControl('', Validators.required),
      calcul_mode_id: new FormControl('', Validators.required),
      domaine_id: new FormControl('', Validators.required),
      name_question: new FormControl('', Validators.required),
      order_by: new FormControl('', Validators.required),
      question_description: new FormControl('')
    });

    sender.addRow(this.formGroup);
  }

  // pour la modification d'une réponse
  public editHandler({ sender, rowIndex, dataItem }) {
    this.getDomainesByAxe(dataItem.axe_id);
    this.selectedValueCalculMode_id = dataItem.calcul_mode_id;
    this.selectedValueAxe_id = dataItem.axe_id;
    this.selectedValueDomaine_id = dataItem.domaine_id;
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.question_id),
      axe_id: new FormControl(dataItem.axe_id, Validators.required),
      calcul_mode_id: new FormControl(dataItem.calcul_mode_id, Validators.required),
      domaine_id: new FormControl(dataItem.domaine_id, Validators.required),
      name_question: new FormControl(dataItem.name_question, Validators.required),
      order_by: new FormControl(dataItem.order_by, Validators.required),
      question_description: new FormControl(dataItem.question_description, Validators.required)
    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  // pour annuler la modification
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // pour la sauvegarde d'une réponse
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {

    formGroup.value.companyId = this.helperService.getCompanyIdForSearch();
    formGroup.value.questionnaire_version_id = this.questionnaire_version_id;
    formGroup.value.axe_id = this.selectedValueAxe_id;
    formGroup.value.domaine_id = this.selectedValueDomaine_id;
    formGroup.value.calcul_mode_id = this.selectedValueCalculMode_id;
    const question: Questions = formGroup.value;

    this.questionService.updateQuestion(question, isNew).subscribe(r => {
      const resp = r.json();
    });

    this.search();
    sender.closeRow(rowIndex);
  }

  // pour la suppression d'une réponse
  removeHandler() {
    this.questionService.deleteQuestion(this.dataItem_id).subscribe(r => {
      const resp = r.json();
      if (resp == '1') {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  // pour fermer l'édition
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // pour la popup de confirmation de suppression
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.question_id;
    this.dataItem_name = dataItem.name_question;
  }

  // pour aller au grid de reponses
  goToResponse(questionId) {
    this.route.navigate(['questionnaires/versions/question/reponse/', this.questionnaire_id, this.questionnaire_version_id, questionId]);
  }

  // pour retourner au questionnaire
  goToQuestionnaire() {
    this.route.navigate(['/questionnaires/versions/', this.questionnaire_id]);
  }
}
