import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  //déclaration et initialisation des variables
  public isAdmKYU = false;
  public chemin_company_logo = 'kyu.png';
  public company_name = 'KYU Lab';
  public isOpenSideBare: boolean = true;

  constructor() {
    if (sessionStorage.getItem('company_logo') != null && sessionStorage.getItem('company_logo') != 'null' && sessionStorage.getItem('company_logo') != '') {
      this.company_name = sessionStorage.getItem('company_name');
    }

    if (sessionStorage.getItem('admKYU') != null && sessionStorage.getItem('admKYU') != undefined) {
      console.log(sessionStorage.getItem('admKYU'));
      if (sessionStorage.getItem('admKYU') == '1') {
        this.isAdmKYU = true;

      } else {
        this.chemin_company_logo = sessionStorage.getItem('company_logo');
      }
    }
  }

  ngOnInit() {
    if (screen.availWidth <= 999) {
      this.isOpenSideBare = false;
      var el = document.querySelector("#wrapper #content-wrapper #content") as HTMLElement;
      el.style.marginLeft = "55px";
    }
  }
  //pour la gestion du side bar
  toggleSideBare() {
    this.isOpenSideBare = !this.isOpenSideBare;
    var el = document.querySelector("#wrapper #content-wrapper #content") as HTMLElement;
    el.style.marginLeft = "55px";
    if (this.isOpenSideBare === true) {
      el.style.marginLeft = "";
    }
  }
}
