import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { AxesService, DomainesService } from 'src/app/services/services';
import { Domaines } from 'src/app/models/domaines';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/services/helpers/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-domaines',
  templateUrl: './domaines.component.html',
  styleUrls: ['./domaines.component.scss']
})

export class DomainesComponent implements OnInit {
  //déclaration et initialisation des variables
  public name: string = null;
  public description: string = null;
  public axe_id: string = '';
  public gridData: any[] = [];
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public company_id: string = null;
  public axes: any[] = [];
  public selectedValueAxe_id = 0;
  public view: Observable<GridDataResult>;
  public gridView: GridDataResult;
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public gridState: State = {
    sort: [{ 'field': 'name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(
    private axeService: AxesService,
    private domaineService: DomainesService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router) {

    if (sessionStorage.getItem('admKYU') == '1') {
      this.router.navigate(['users']);
    }
  }

  ngOnInit() {
    //pour pointer en haut de la page
    window.scroll(0, 0);
    this.search();
    this.getAxes();
  }

  // recuperer les domaines
  getDomaines() {
    this.domaineService.getDomaines().subscribe(r => {
      const resp = r.json();
      this.gridData = resp.data;
    });
  }

  // recuperer les axes d'une company par son id
  getAxes() {
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.axeService.getAxes(this.company_id).subscribe(r => {
      const resp = r.json();
      this.axes = resp.data;
    });
  }

  // rechercher les domaines name,description,axe_id, company_id,
  search() {
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.spinner.show();

    this.domaineService.search(this.name, this.description, this.axe_id,
      this.company_id, this.gridState).subscribe(r => {
        const resp = r.json();
        this.gridData = resp.data;

        this.gridView = {
          data: resp.data,
          total: resp.total
        };

        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
      });
  }

  // supprimer domaine
  deleteDomaines(item) {
    this.domaineService.deleteDomaine(item.id).subscribe(r => {
      const resp = r.json();

      if (resp.result == 1) {
        this.search();
      }
    });
  }

  // en cas de chengement datagrid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // ajouter une nouvelle ligne domaine
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      id: new FormControl(),
      axe_id: new FormControl(''),
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
    });

    sender.addRow(this.formGroup);
  }

  // editer une nouvelle ligne domaine
  public editHandler({ sender, rowIndex, dataItem }) {
    this.selectedValueAxe_id = dataItem.axe_id;
    console.log(this.selectedValueAxe_id);
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      axe_id: new FormControl(dataItem.axe_id, Validators.required),
      name: new FormControl(dataItem.name, Validators.required),
      description: new FormControl(dataItem.description),

    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  // annuler la modification d'une ligne domaine
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // annuler la modification d'une ligne domaine
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const domaines: Domaines = formGroup.value;
    formGroup.value.axe_id = this.selectedValueAxe_id;

    this.domaineService.save(domaines, isNew).subscribe(r => {
      const resp = r.json();
    });

    this.search();
    sender.closeRow(rowIndex);
  }

  // supprimer une ligne domaine
  removeHandler() {
    this.domaineService.deleteDomaine(this.dataItem_id).subscribe(r => {
      const resp = r.json();

      if (resp == 1) {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  // cacher la nouvelle ligne vide
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // pour ouvrir le message de confirmation
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.id;
    this.dataItem_name = dataItem.name;
  }
}
