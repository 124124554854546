export class QuestionnaireVersion {
  public version?: string = "";
  public startDate?:Date;
  public endDate?: Date;
  public active?: string;
  constructor() {
    this.version = "";
    this.startDate = null;
    this.endDate = null;
    this.active = '0'; 
  }
}
