import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  //déclaration et initialisation des variables
  public cuurentPassword: string;
  public newPassword: string;
  public newPasswordConfirmation: string;
  public isAlert: boolean = false;
  public message: string = "";
  public typeAlert: string = "";

  constructor(private usersService: UsersService, private router: Router) {
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);
  }

  // changement de mot de passe 
  changePassword() {
    if (this.newPassword != "" && this.newPassword == this.newPasswordConfirmation) {
      this.usersService.changePassword(this.cuurentPassword, this.newPassword).subscribe(r => {
        let resp = r.json();
        this.isAlert = true;

        if (resp.result == "1") {
          this.message = "Le changement de votre mot de passe a été fait avec succès";
          this.typeAlert = "alert-success";
        } else {
          this.message = resp.result;
          this.typeAlert = "alert-danger";
        }
      });
    } else {
      if (this.newPassword != this.newPasswordConfirmation) {
        this.isAlert = true;
        this.message = "La confirmation du nouveau mot de passe est incorrecte";
        this.typeAlert = "alert-danger";
      }
    }
  }

  //aller au user
  goToHome() {
    this.router.navigate(['/users']);
  }
}
