import { Injectable } from '@angular/core';
import { ApiService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private api: ApiService) {
    }

    // connecter admin
    login(email, password) {
        const obj = {
            email: email,
          password: password,

        };

        return this.api.post('loginadmin', obj);
    }

    // verifier si user est connected
    isConnected() {
        let token = sessionStorage.getItem('token');
        let result = false;

        if (token != null) {
            result = true;
        }

        return result;
    }
}
