import { Component, OnInit } from '@angular/core';
import { TemoignagesService } from '../../../services/services';
import { CompaniesService } from '../../../services/services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HelperService } from 'src/app/services/helpers/helper.service';

@Component({
  selector: 'app-detail-temoignage',
  templateUrl: './detail-temoignage.component.html',
  styleUrls: ['./detail-temoignage.component.scss']
})

export class DetailTemoignageComponent implements OnInit {
  //déclaration et initialisation des variables 
  public companies: any[] = [];
  public temoignages: any;
  public temoignage: any[] = [];
  public id: number = 0;
  public company_id: string = null;
  public name: string = '';
  public description: string = '';
  public defi: string = '';
  public contexte: string = '';
  public defi_details: string = '';
  public apports: string = '';
  public difficultes: string = '';
  public impacts_metiers: string = '';
  public impacts_competences: string = '';
  public Editor = ClassicEditor;

  constructor(private route: ActivatedRoute,
    private temoignageService: TemoignagesService,
    private companyService: CompaniesService,
    private helperService: HelperService,
    private router: Router,
    private toastr: ToastrService) {
    if (sessionStorage.getItem('admKYU') == '1') {
      this.router.navigate(['/users']);
    }
    //on récupère le paramètre passé 
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    this.load();
  }

  //poiur obtenir toutes les companies
  getCompanies() {
    this.companyService.getCompanies().subscribe((resp: any) => {
      let r = resp.json();
      this.companies = r.data;
    });
  }

  //pour sauvegarder un témoignage
  saveTemoignage() {
    this.company_id = this.helperService.getCompanyIdForSearch();
    if (this.company_id == null || this.name == '' || this.description == '' ||
      this.defi == '' || this.contexte == '' || this.defi_details == '' || this.apports == ''
      || this.difficultes == '' || this.impacts_metiers == '' || this.impacts_competences == '') {
      this.toastr.info('Veuillez renseigner  les champs obligatoires (*) !!');
    } else {
      this.temoignageService.saveTemoignage(
        this.id,
        this.company_id,
        this.name,
        this.description,
        this.defi,
        this.contexte,
        this.defi_details,
        this.apports,
        this.difficultes,
        this.impacts_metiers,
        this.impacts_competences).subscribe((resp: any) => {
          let r = resp.json();
          this.temoignages = r.data;

          this.toastr.success('Ajouté avec succès!!!');
          this.router.navigateByUrl('/temoignages');
        });
    }
  }

  //pour retourner à la page de la liste après ajout 
  GoToTemoignage() {
    this.router.navigateByUrl('/temoignages');
  }

  //pour charger le temoignage à modifier 
  load() {
    this.temoignageService.load(this.id).subscribe(r => {
      let resp = r.json();
      let obj: any = resp.data;
      this.name = obj.name;
      this.description = obj.description;
      this.contexte = obj.contexte;
      this.defi = obj.defi;
      this.apports = obj.apports;
      this.defi_details = obj.defi_details;
      this.difficultes = obj.difficultes;
      this.impacts_metiers = obj.impacts_metiers;
      this.impacts_competences = obj.impacts_competences;
    });
  }
}
