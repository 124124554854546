import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/services';
import { CompaniesService } from '../../../services/companies/companies.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Users } from '../../../models/users';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../../services/helpers/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
  //déclarer et initialisation des variables 
  public name: string = null;
  public email: string = null;
  public client: string = null;
  public profil: string = null;
  public isNewUser: boolean = false;
  public companies: any[] = [];
  public profils: any[] = [];
  public password: string;
  public confirmationPassword: string;
  public messageVAlidationPassword: string = '';
  public isvalidePassord: boolean = true;
  public selectedValueCompany_id = 0;
  public selectedValueProfil_id = 0;
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public isAdmKYU = false;
  public item: any;
  public company_id: string = null;
  public view: Observable<any>;
  public gridView: GridDataResult;
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public obj: Users;
  public gridState: State = {
    sort: [{ 'field': 'first_name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(private usersService: UsersService,
    private companiesService: CompaniesService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router) {

    if (sessionStorage.getItem('admKYU') != null && sessionStorage.getItem('admKYU') != undefined) {
      if (sessionStorage.getItem('admKYU') == '1') {
        this.isAdmKYU = true;
      }
    }
  }

  ngOnInit() {
    //on déclarer et initialisation des variables
    window.scroll(0, 0);

    // pour avoir toutes les company
    if (this.isAdmKYU == true) {
      this.getCompanies();
    }

    // pour la recherche
    this.search();

    // pour avoir les profils
    this.getAllProfils();
  }

  // ouvrire le model de la modification de password
  open(content, item) {
    this.password = '';
    this.confirmationPassword = '';
    this.messageVAlidationPassword = '';
    this.isvalidePassord = true;
    this.item = item;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  // pour valide le changement de mot de passe
  valider() {
    if (this.password != '' && this.confirmationPassword != '' && this.password == this.confirmationPassword) {
      this.isvalidePassord = true;
      this.setPassword(this.item);
      this.modalService.dismissAll();
    } else {
      this.isvalidePassord = false;
      this.messageVAlidationPassword = 'Le mot de passe invalide!';
    }
  }

  // pour la recherche
  search() {
    this.spinner.show();
    this.company_id = this.helperService.getCompanyIdForSearch();

    this.usersService.search(this.name, this.email, this.client, this.profil, this.company_id, this.gridState).subscribe(r => {
      let resp = r.json();

      this.gridView = {
        data: resp.data,
        total: resp.total
      };

      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }

  // pour supprimer une company
  deleteCompanies(item) {
    this.usersService.deleteUsers(item.id).subscribe(r => {
      let resp = r.json();

      if (resp.result == 1) {
        this.search();
      }
    });
  }

  // quand on change les variable du grid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // pour ajouter une ligne
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      'id': new FormControl(),
      'first_name': new FormControl('', Validators.required),
      'last_name': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.email, Validators.required]),
      'password': new FormControl(),
      'company_id': new FormControl(),
      'profil_id': new FormControl(),
    });
    sender.addRow(this.formGroup);
  }

  // pour modifier une ligne
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.selectedValueCompany_id = dataItem.company_id;
    this.selectedValueProfil_id = dataItem.profil_id;

    this.formGroup = new FormGroup({
      'id': new FormControl(dataItem.id),
      'first_name': new FormControl(dataItem.first_name, Validators.required),
      'last_name': new FormControl(dataItem.last_name, Validators.required),
      'email': new FormControl(dataItem.email, Validators.required),
      'password': new FormControl(dataItem.password),
      'company_id': new FormControl(dataItem.company_id),
      'profil_id': new FormControl(dataItem.profil_id),

    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
    this.search();
  }

  // pour annuler
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // pour sauvgarder une ligne
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    formGroup.value.company_id = this.helperService.getCompanyIdForSearch();
    formGroup.value.profil_id = this.selectedValueProfil_id;
    const users: Users = formGroup.value;
    this.usersService.save(users, isNew).subscribe(r => {
      let resp = r.json();
      if (resp.result == 1) {
        
        this.search();
      }
    });

    sender.closeRow(rowIndex);
  }

  // pour supprimer une ligne
  removeHandler() {
    this.usersService.deleteUsers(this.dataItem_id).subscribe(r => {
      let resp = r.json();

      if (resp.result == 1) {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  // pour fermer la line
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // pour avoir toutes les company
  getCompanies() {
    this.companiesService.getCompanies().subscribe((resp: any) => {
      let r = resp.json();
      this.companies = r.data;
    });
  }

  // pour avoir les profils
  getAllProfils() {
    this.usersService.getAllProfils().subscribe((resp: any) => {
      let r = resp.json();
      this.profils = r.data;
    });
  }

  // pour enregistrer le password
  public setPassword(item) {
    this.usersService.setPasswordUser(item.id, this.password).subscribe(r => {
      let resp = r.json();
    });
  }

  // les methodes de model
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.id;
    this.dataItem_name = dataItem.first_name + ' ' + dataItem.last_name;
  }

  //pour exporter les utilisateurs FO
  export() {
    this.spinner.show();
    this.usersService.downloadExcel().subscribe(r => {
      const data = r;
      let date = new Date().toISOString().slice(0, 10);
  
      let heureMS = new Date().toISOString().slice(11, 19).replace('_', ':');
     
      var fileName = 'Utilisateurs-' + date + '-' + heureMS + '.xlsx';
      
      var file = new Blob([r.blob()], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ; charset=utf-8"
      });

      FileSaver.saveAs(file, 'Utilisateurs-export.xlsx');
      this.spinner.hide();
    });
  }
}
