import { Injectable } from '@angular/core';
import { ApiService } from '../services';

const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

@Injectable({
  providedIn: 'root'
})

export class TemoignagesService {
  //déclaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  //pour rechercher les temoignages
  search(name, description, client, company_id, gridState) {
    const obj = {
      name: name,
      description: description,
      client: client,
      company_id: company_id,
      gridState: gridState,
      user_id: this.user_id
    };

    return this.api.post('searchTemoignages', obj);
  }

  //récupèrer les temoignages
  getTemoignages() {
    const obj = {
      user_id: this.user_id
    };
    return this.api.post('temoignages', obj);
  }

  //supprimer un temoignage
  deleteTemoignage(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteTemoignage', obj);
  }

  //sauvgarder un temoignage
  saveTemoignage(id, company_id, name, description, defi, contexte, defi_details, apports, difficultes, impacts_metiers, impacts_competences) {
    const obj = {
      id: id,
      company_id: company_id,
      name: name,
      description: description,
      defi: defi,
      contexte: contexte,
      defi_details: defi_details,
      apports: apports,
      difficultes: difficultes,
      impacts_metiers: impacts_metiers,
      impacts_competences: impacts_competences,
      user_id: this.user_id
    };

    return this.api.post('saveTemoignage', obj);
  }

  //récuppèrer un temoignage par id
  load(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('loadTemoignages/' + id, obj);
  }
}
