import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AxesService, AxeVersionsService } from '../../../services/services';
import { HelperService } from '../../../services/helpers/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AxeVesion } from 'src/app/models/axeVersion';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-axe-versions',
  templateUrl: './axe-versions.component.html',
  styleUrls: ['./axe-versions.component.scss']
})

export class AxeVersionsComponent implements OnInit {
  //déclaration et initialisation des variables
  public orderBy: number = 1;
  public axe_name: string = null;
  public axe_id: string = null;
  public gridData: any[] = [];
  public axes: any[] = [];
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public company_id: string = null;
  public questionnaire_version_id: string = null;
  public questionnaire_id: string = null;
  public selectedValueAxe_id = 0;
  public hide_in_graph = 0;
  public view: Observable<GridDataResult>;
  public gridView: GridDataResult;
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public gridState: State = {
    sort: [{ 'field': 'name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };
  
  constructor(
    private axeService: AxesService,
    private QuestionnaireVAxeService: AxeVersionsService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) {
      if (sessionStorage.getItem('admKYU') == '1') {
        this.router.navigate(['/users']);
      }
    // on récupère le paramètre passé
    this.route.params.subscribe(params => {
      this.questionnaire_version_id = params['idQuestionnaireVersion'];
      this.questionnaire_id = params['idQuestionnaire'];
    });
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    // pour obtenir les axes versions 
    this.getQuestionnaireAxeVersion();

    // pour rechercher les axes versions 
    this.search();

    // recuperer tous les axes d'une company
    this.getAxes();
  }

  // recuperer tous les axes d'une company
  getAxes() {
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.axeService.getAxes(this.company_id).subscribe(r => {
      let resp = r.json();
      this.axes = resp.data;
    });
  }

  // recuperer QuestionnaireAxeVersion 
  getQuestionnaireAxeVersion() {
    // on fait l'appel de l'api avec le paramètre récupéré
    this.QuestionnaireVAxeService.getAllQuetionnaireVersionsAxe(this.questionnaire_version_id).subscribe(r => {
      let resp = r.json();
      this.gridData = resp;
    });
  }

  // pour rechercher les axes versions 
  search() {
    this.spinner.show();
    this.company_id = this.helperService.getCompanyIdForSearch();

    this.QuestionnaireVAxeService.search(this.questionnaire_version_id, this.axe_name, this.orderBy, this.company_id, this.gridState).subscribe(r => {
      let resp = r.json();
      this.gridData = resp.data;

      this.gridView = {
        data: resp.data,
        total: resp.total
      };
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }

  // suppression axes version
  deleteAxeVersions(item) {
    this.QuestionnaireVAxeService.deleteQuestionnaireVersionsAxe(item.id).subscribe(r => {
      let resp = r.json();
      if (resp.result == 1) {
        this.search();
      }
    });
  }

  // detection de changement du grid  
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  //pour ajouter une ligne
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      id: new FormControl(),
      questionnaire_version_id: new FormControl(''),
      orderBy: new FormControl('', Validators.required),
      axe_id: new FormControl(''),
      hide_in_graph: new FormControl('', Validators.required),
    });

    sender.addRow(this.formGroup);
  }

  //pour modifier une ligne
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.selectedValueAxe_id = dataItem.axe_id;

    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      questionnaire_version_id: new FormControl(dataItem.questionnaire_version_id),
      orderBy: new FormControl(dataItem.orderBy, Validators.required),
      axe_id: new FormControl(dataItem.axe_id, Validators.required),
      hide_in_graph: new FormControl(dataItem.hide_in_graph, Validators.required),
    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  //pour annuler la modification ou l'ajout d'une ligne
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // pour la sauvegarde
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    formGroup.value.company_id = this.helperService.getCompanyIdForSearch();
    formGroup.value.axe_id = this.selectedValueAxe_id;
    formGroup.value.questionnaire_version_id = this.questionnaire_version_id;

    const axeVersions: AxeVesion = formGroup.value;

    this.QuestionnaireVAxeService.save(axeVersions, isNew).subscribe(r => {
      let resp = r.json();
    });

    this.search();
    sender.closeRow(rowIndex);
  }

  // pour la suppression
  removeHandler() {
    this.QuestionnaireVAxeService.deleteQuestionnaireVersionsAxe(this.dataItem_id).subscribe(r => {
      let resp = r.json();

      if (resp == 1) {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  //pour la fermeture
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //les methodes de model
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.id;
    this.dataItem_name = dataItem.name;
  }

  // pour retourner au questionnaire
  goToQuestionnaire() {
    this.router.navigate(['/questionnaires/versions/', this.questionnaire_id]);
  }

  // state column hidden axe
  isChecked(dataItem) {
    var boolresult = false;

    if (dataItem.hide_in_graph == 1 || dataItem.hide_in_graph == '1') {
      boolresult = true;
    }

    return boolresult;
  }

  public selectionChange(value: any): void {
    console.log(this.selectedValueAxe_id);
  }
}
