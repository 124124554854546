import { Injectable } from '@angular/core';
import { ApiService } from '../services';

const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

@Injectable({
  providedIn: 'root'
})

export class DomainesService {
  //déclaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // rechercher domaines apr name, axe_id company_id and gridState
  search(name, description, axe_id, company_id, gridState) {
    const obj = {
      name: name,
      description: description,
      axe_id: axe_id,
      company_id: company_id,
      gridState: gridState,
      user_id: this.user_id
    };

    return this.api.post('searchDomaines', obj);
  }

  // recuperer les domaines
  getDomaines() {
    const obj = {
      user_id: this.user_id
    };

    return this.api.post('domaines', obj);
  }

  // recuperer les axes appartire d'un axe id
  getDomainesByAxeId(axe_id) {
    let obj = {
      axe_id: axe_id,
      user_id: this.user_id
    }

    return this.api.post('getDomainesByAxeId', obj);
  }

  // recuperer le domaine d'un axe par comapany_id
  getDomainesAxe(comapany_id) {
    const obj = {
      comapany_id: comapany_id,
      user_id: this.user_id
    };

    return this.api.post('getDomainesAxe', obj);
  }

  // supprimer un domaine
  deleteDomaine(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteDomaine', obj);
  }

  // sauvegarder domaines
  public save(data: any, isNew?: boolean) {
    data['user_id'] = this.user_id
    return this.api.post('saveDomaines', data);
  }
}
