import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Questions } from '../../models/questions';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  //déclaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // recuperer question par QuestionnairVersion 
  getQuestionByQuestionnairVersionId(questionnair_version_id) {
    let obj = {
      questionnair_version_id: questionnair_version_id,
      user_id: this.user_id
    }
    return this.api.post("getQuestionByQuestionnairVersionId", obj);
  }

  // sauvegrder question
  saveQuestion(question: Questions) {

    let obj = {
      questionnaire_version_id: question.questionnair_version_id,
      axe_id: question.axe,
      name: question.question,
      description: question.description,
      domaine_id: question.domaine,
      orderby: question.ordre,
      calcul_mode_id: question.calcule_mode_id,
      user_id: this.user_id
    };
    return this.api.post('saveQuestion', obj);
  }

  // pour rechercher sur la liste des  questions
  search(axe_id, calcul_mode_id, domaine_id, name_question, company_id, order_by, question_description, gridState, questionnaire_version_id) {
    const obj = {
      axe_id: axe_id,
      calcul_mode_id: calcul_mode_id,
      domaine_id: domaine_id,
      name_question: name_question,
      company_id: company_id,
      order_by: order_by,
      question_description: question_description,
      gridState: gridState,
      questionnaire_version_id: questionnaire_version_id,
      user_id: this.user_id
    };
    return this.api.post('searchQuestion', obj);
  }

  // pour supprimer les réponse d'une question
  deleteQuestion(id) {
    const obj = {
      question_id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteQuestion', obj);
  }

  // pour une  question sauvegarder après modification
  public updateQuestion(data: any, isNew?: boolean) {
    data['user_id'] = this.user_id;
    return this.api.post('updateQuestion', data);
  }
}


