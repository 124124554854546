import { LoginComponent } from './login/login.component';
import { CompaniesComponent } from './companies/companies.component';
import { AxesComponent } from './axes/axes.component';
import { CompanyUrlsComponent } from './companies/company-urls/company-urls.component';
import { DomainesComponent } from './domaines/domaines.component';
import { TemoignagesComponent } from './temoignages/temoignages.component';
import { DetailTemoignageComponent } from './temoignages/detail-temoignage/detail-temoignage.component';
import { UsersComponent } from './users/users/users.component';
import { FichesActionsComponent } from './fiches-actions/fiches-actions.component';
import { DetailFichesActionsComponent } from './fiches-actions/detail-fiche-action/detail-fiches-actions.component';
import { ConfirmationPasswordComponent } from './users/confirmation-password/confirmation-password.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { QuestionnaireVersionsComponent } from './questionnaire-versions/questionnaire-versions.component';
import { AxeVersionsComponent } from './questionnaire-versions/axe-versions/axe-versions.component';
import { QuestionsComponent } from './questionnaire-versions/questions/questions.component';
import { DetailQuestionComponent } from './questionnaire-versions/questions/detail-question/detail-question.component';
import { ReponsesComponent } from './questionnaire-versions/reponses/reponses.component';
import { QuestionnairesComponent } from './questionnaires/questionnaires.component';

export {
  LoginComponent,
  CompaniesComponent,
  AxesComponent,
  CompanyUrlsComponent,
  DomainesComponent,
  TemoignagesComponent,
  DetailTemoignageComponent,
  UsersComponent,
  FichesActionsComponent,
  DetailFichesActionsComponent,
  ConfirmationPasswordComponent,
  ChangePasswordComponent,
  QuestionnaireVersionsComponent,
  AxeVersionsComponent,
  QuestionsComponent,
  DetailQuestionComponent,
  ReponsesComponent,
  QuestionnairesComponent
};
