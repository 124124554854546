import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReponsesService {
  //déclaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // pour obtenir les  réponses d'une question
  getReponseQuestionById(questionId: any) {
    let obj = {
      questionId: questionId,
      user_id: this.user_id
    };
    return this.api.post("responseQuestion", obj);
  }

  // recuperer Question par son id
  getQuestionNameById(questionId) {
    let obj = {
      questionId: questionId,
      user_id: this.user_id
    };
    return this.api.post("getQuestionName", obj);
  }

  // pour rechercher les  reponses d'une question
  search(questionId, name, description, note, companyId, gridState) {
    const obj = {
      questionId: questionId,
      name: name,
      description: description,
      note: note,
      companyId: companyId,
      gridState: gridState,
      user_id: this.user_id
    };
    return this.api.post('searchResponseQuestion', obj);
  }

  // pour sauvegarder les  réponses d'une question
  public save(data: any, isNew?: boolean) {
    data['user_id'] = this.user_id;
    return this.api.post('saveResponseQuestion', data);
  }

  // pour supprimer les réponse d'une question
  deleteQuestion(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };
    return this.api.post('deleteResponseQuestion', obj);
  }
}
