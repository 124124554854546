import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class HelperService {
    constructor() {
    }

    // recuper company_id from sessionStorage
    public getCompanyIdForSearch() {
      var company_id = '';
      if (sessionStorage.getItem('admKYU') != '1') {

        company_id = sessionStorage.getItem('company_id');
      } else {
        company_id = sessionStorage.getItem('company_id');
      }

        return company_id;
    }
}
