import { Injectable } from '@angular/core';
import { ApiService } from '../services';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  //déclaration et initialisation des variables
  public user_id;
  constructor(private api: ApiService) {
  }

  // rechercher utilisateurs
  search(name, email, client, profil, company_id, gridState) {
    this.user_id = sessionStorage.getItem('user_id');

    const obj = {
      name: name,
      email: email,
      client: client,
      profil: profil,
      company_id: company_id,
      gridState: gridState,
      user_id: this.user_id
    };
    return this.api.post('searchUsers', obj);
  }

  // supprimer utilisateur
  deleteUsers(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteUsers', obj);
  }

  // sauvegarder utilisateur
  public save(data: any, isNew?: boolean) {
    data['user_id'] = this.user_id;
    return this.api.post('saveUsers', data);
  }

  // recuperer touts les utilisateur
  public getAllProfils() {
    const obj = {
      user_id: this.user_id
    };
    return this.api.post('getAllProfils', obj);
  }

  // setPassword
  public setPasswordUser(id, password) {
    const obj = {
      id: id,
      password: password,
      user_id: this.user_id
    };

    return this.api.post('setPassword', obj);
  }

  // recuperer un fichier excel des utilisateur
  public downloadExcel() {
    const obj = {
      user_id: this.user_id
    };
    return this.api.getExcelSave('downloadExcel', obj);
  }

  // export des resultat des utilisateur
  public exportUserResults(id) {
    this.user_id = sessionStorage.getItem('user_id');
    const obj = {
      questionnaireVersionId: id,
      user_id: this.user_id
    };
    return this.api.getExcelSave('exportUserResult', obj);
  }

  // setPassword
  public confirmationPassword(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('confirmationPassword', obj);
  }

  // changer mot de passe 
  public changePassword(currentPassword, newPassword) {
    var user_id = "";
    user_id = sessionStorage.getItem('user_id');

    const obj = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      user_id: user_id
    };

    return this.api.post('changePassword', obj);
  }

  // setPassword
  public forgotPassword(email: string) {
    var user_id = "";
    user_id = sessionStorage.getItem('user_id');

    const obj = {
      email: email,
      user_id: user_id
    };

    return this.api.post('forgotPassword', obj);
  }

}
