import { Component } from '@angular/core';
import { AuthService } from './services/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  //déclaration et initialisation des variables
  public title = 'KYU Lab - Questionnaire Manager';
  public isConnected: boolean = false;
  public NoConnected: string = '';

  constructor(private auth: AuthService, private router: Router) {
    //On check la connexion user
    this.checkAuthentificationUser();
  }

  ngOnInit() {
  }

  //pour checker la connexion
  public checkConnexion() {
    this.checkAuthentificationUser();
  }

  //pour tester l'authentification
  checkAuthentificationUser() {
    this.isConnected = this.auth.isConnected();
    var currentUrl = window.location.href;

    if (currentUrl.indexOf("confirmationPassword") > 0) {
      this.NoConnected = 'no-connected';
    } else {
      if (this.isConnected == false) {
        this.NoConnected = 'no-connected';
        this.router.navigateByUrl('login');
      } else {
        this.NoConnected = '';
      }
    }
  }
}
