export class FichesActions {
  public id: number;
  public company_id: number;
  public axe_id: number;
  public name: string = "";
  public description: string = "";
  public note: number;
  public benefices: string = "";
  public impacts: string = "";
  public intCompany: string = null;

  constructor() {
    this.id = 0;
    this.company_id = null;
    this.axe_id = null;
    this.name = "";
    this.description = "";
    this.note = null;
    this.benefices = "";
    this.impacts = "";
    this.intCompany = "";
  }
}
