import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { FichesActions } from 'src/app/models/fichesActions';

@Injectable({
  providedIn: 'root'
})

export class FichesActionsService {
  //déclaration et initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // recuperer les fiches d'action
  getFichesActions() {
    const obj = {
      user_id: this.user_id
    };

    return this.api.post('fichesActions', obj);
  }

  // rechercher fiche d'action 
  getSearchFichesActions(name: string, description: string, axe_id: string, company_id: string, benefices: string, impacts: string, note: number, gridState: any) {
    const obj = {
      name: name,
      description: description,
      axe_id: axe_id,
      company_id: company_id,
      benefices: benefices,
      impacts: impacts,
      note: note,
      gridState: gridState,
      user_id: this.user_id
    };

    return this.api.post('searchFichesActions', obj);
  }

  // seuvgarder fiche d'action
  saveFicheAction(ficheAction: FichesActions) {
    const obj = {
      id: ficheAction.id,
      company_id: ficheAction.company_id,
      axe_id: ficheAction.axe_id,
      name: ficheAction.name,
      note: ficheAction.note,
      description: ficheAction.description,
      benefices: ficheAction.benefices,
      impacts: ficheAction.impacts,
      intCompany: ficheAction.intCompany,
      user_id: this.user_id
    };

    return this.api.post('saveFichesActions', obj);
  }

  // recuperer FicheAction
  load(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('loadFicheAction', obj);
  }

  // delete fiche d'action
  deleteFicheAction(id) {
    const obj = {
      id: id,
      user_id: this.user_id
    };

    return this.api.post('deleteFicheAction', obj);
  }
}
