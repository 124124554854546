import { Injectable } from '@angular/core';
import { ApiService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class QuestionnairesService {
  //déclaration initialisation des variables
  public user_id;

  constructor(private api: ApiService) {
    this.user_id = sessionStorage.getItem('user_id');
  }

  // recuperer les questionnaires par company_id,questionnaire_name et gridState
  getQuestionnaires(company_id, questionnaire_name, gridState) {
    let obj = {
      company_id: company_id,
      name: questionnaire_name,
      gridState: gridState,
      user_id: this.user_id
    };

    return this.api.post("getQuestionnaires", obj);
  }

  // pour la sauvegarde
  save(company_id, formGroup) {
    let obj = {
      company_id: company_id,
      id: formGroup.id,
      name: formGroup.name,
      user_id: this.user_id
    }

    return this.api.post("saveQuestionnaire", obj);
  }

  // pour supprimer un questionnaire
  deleteQuestionnaire(id, company_id) {
    const obj = {
      id: id,
      company_id: company_id,
      user_id: this.user_id
    };

    return this.api.post('deleteQuestionnaire', obj);
  }
}
