import { Component, OnInit } from '@angular/core';
import { AuthService, UsersService } from '../../services/services';
import { ApiService } from '../../services/services';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  //déclaration et initialisation des variable
  public email: string = '';
  public password: string = '';
  public isconnected: boolean = false;
  public erreur = '';
  public isForgotPassword: boolean = false;

  constructor(private authService: AuthService,
    private router: Router,
    private usersService: UsersService,
    private appComponent: AppComponent) {
  }
  
  ngOnInit() {
    //pour pointer en haut de la page
    window.scroll(0, 0);
    this.isconnected = this.authService.isConnected();

    if (this.isconnected == true) {
      window.location.href = '/companies';
    }

    document.getElementById('content-wrapper').classList.add('login-page');
  }

  //quand en ferme
  ngOnDestroy() {
    document.getElementById('content-wrapper').classList.remove('login-page');
  }

  // connecter admin
  connecte() {
    this.authService.login(this.email, this.password).subscribe(r => {
      let resp = r.json();
      if (resp.error != undefined && resp.error != 'undefined') {
        this.erreur = resp.error;
      }

      if (resp.token != null && resp.token != 'null' && resp.token != undefined && resp.token != 'undefined' && resp.token != '') {
        sessionStorage.setItem('token', resp.token);
        if (resp.infoUser.name != null) {
          sessionStorage.setItem('user_name', resp.infoUser.name);
          sessionStorage.setItem('user_id', resp.infoUser.user_id);
          sessionStorage.setItem('admKYU', resp.infoUser.admkyu);
          sessionStorage.setItem('company_id', resp.infoUser.company_id);
          sessionStorage.setItem('company_logo', resp.infoUser.company_logo);
          sessionStorage.setItem('company_name', resp.infoUser.company_name);
        }

        if(sessionStorage.getItem('admKYU') == '0'){
          this.router.navigate(['/questionnaires']);
        }else{
          this.router.navigate(['/users']);
        }
        this.appComponent.checkConnexion();
      }
    },
      error => {
        console.log(error);
        this.erreur = "Nom d'utilisateur ou le mot de passe est incorrect";
      });
  }

  // demande mot de passe
  forgotPassword() {
    this.usersService.forgotPassword(this.email).subscribe(r => {
      let resp = r.json();
    });

  }

  //pour checker le password
  checkForgotPassword() {
    this.isForgotPassword = true;
  }

  // retourner au page de login
  CancelForgotPassword() {
    this.isForgotPassword = false;
  }
}
