import { State } from '@progress/kendo-data-query';
import { HelperService } from './../../services/helpers/helper.service';
import { Component, OnInit } from '@angular/core';
import { QuestionnairesService } from '../../services/services';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss']
})

export class QuestionnairesComponent implements OnInit {
  //declaration et initialisation des variables
  public questionnaire_name: string = "";
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public company_id: string = "";
  public editedRowIndex: number;
  public questionnaire_id = '';
  public formGroup: FormGroup;
  public gridView: GridDataResult;
  public gridState: State = {
    sort: [{ 'field': 'name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(
    private service: QuestionnairesService,
    private router: Router,
    private modalService: NgbModal,
    private helperService: HelperService,
    private spinner: NgxSpinnerService
  ) {
    if (sessionStorage.getItem('admKYU') == '1') {
      this.router.navigate(['/users']);
    }
    this.company_id = sessionStorage.getItem('company_id');
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    // pour la recherche
    this.search();
  }

  // pour la recherche
  search() {
    this.spinner.show();
    this.service.getQuestionnaires(this.company_id, this.questionnaire_name, this.gridState).subscribe(r => {
      const resp = r.json();
      this.gridView = {
        data: resp.data,
        total: resp.total
      };
      this.spinner.hide();

    }, (err) => {
      this.spinner.hide();
    });
  }

  // pour aller aux questionnaire versions
  goToQuestionnaireVersion(questionnaireVersionId) {
    this.router.navigate(['/questionnaires/versions/', questionnaireVersionId])
  }

  // pour dtecter le changement des données du grid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // pour la modification
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      name: new FormControl(dataItem.name, Validators.required),
      id: new FormControl(dataItem.id)
    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  // pour la sauvegarde
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {

    this.service.save(this.company_id, formGroup.value).subscribe(r => {
      const resp = r.json();
      this.search();
    });
    sender.closeRow(rowIndex);
  }

  // pour l'annulation
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }
  // pour fermer l'editeur grid
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // pour la suppression
  removeHandler() {
    this.company_id = this.helperService.getCompanyIdForSearch();
    this.service.deleteQuestionnaire(this.dataItem_id, this.company_id).subscribe(r => {
      const resp = r.json();
      if (resp == 1) {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  // pour ajouter
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
    });

    sender.addRow(this.formGroup);
  }

  // les methodes de model pour la duplication
  openVerticallyCentered(contentDuplicate, dataItem) {
    this.modalService.open(contentDuplicate, { centered: true });
    this.dataItem_name = dataItem.name;
    this.dataItem_id = dataItem.id;
  }
}
