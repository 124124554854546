import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '../../services/services';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Companies } from '../../models/companies';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})

export class CompaniesComponent implements OnInit {
  //déclaration et initialisation des variables
  public companyIdSelected: number = 0;
  public name: string = null;
  public description: string = null;
  public active: string = null;
  public gridData: any[];
  public showCompaniesList: boolean = true;
  public dataItem_id: string = '';
  public dataItem_name: string = '';
  public etat: string = null;
  public company_active: boolean = false;
  public view: Observable<GridDataResult>;
  public gridView: GridDataResult;
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public objCompanies: Companies;
  public gridState: State = {
    sort: [{ 'field': 'name', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(private companiesService: CompaniesService, private modalService: NgbModal, private router: Router, private spinner: NgxSpinnerService) {
    if (sessionStorage.getItem('admKYU') == '0') {
      this.router.navigate(['questionnaires']);
    }
  }

  ngOnInit() {
    //pour pointer en haut de la page
    window.scroll(0, 0);
    this.search();
  }

  // rechecher une company par son nom, description, et son etat 
  search() {
    this.spinner.show();
    this.companiesService.search(this.name, this.description, this.active, this.gridState).subscribe(r => {
      let resp = r.json();
      this.gridData = resp.data;
      this.view = resp.data;
      this.spinner.hide();

      this.gridView = {
        data: resp.data,
        total: resp.total
      };

    }, (err) => {
      this.spinner.hide();
    });
  }

  // supprimer company
  deleteCompanies(item) {
    this.companiesService.deleteCompanies(item.id).subscribe(r => {
      let resp = r.json();

      if (resp.result == 1) {
        this.search();
      }
    });
  }

  // detection changement des grid
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // ajouter une nouvelle ligne
  public addHandler({ sender }) {
    this.closeEditor(sender);
    this.company_active = false;

    this.formGroup = new FormGroup({
      'id': new FormControl(),
      'name': new FormControl('', Validators.required),
      'description': new FormControl(),
      'token': new FormControl(),
      'logo': new FormControl(),
      'active': new FormControl(),
    });

    sender.addRow(this.formGroup);
  }

  // ajouter une nouvelle ligne pour la modification
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.company_active = dataItem.active;

    this.formGroup = new FormGroup({
      'id': new FormControl(dataItem.id),
      'name': new FormControl(dataItem.name, Validators.required),
      'description': new FormControl(dataItem.description),
      'token': new FormControl(dataItem.token),
      'logo': new FormControl(dataItem.logo),
      'active': new FormControl(dataItem.active),
    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  // annuler l'ajoute d'une nouvelle ligne
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // savegarder les modification
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    formGroup.value.active = this.company_active;
    const companies: Companies = formGroup.value;

    this.spinner.show();
    this.companiesService.save(companies, isNew).subscribe(r => {
      let resp = r.json();
      if (resp.result == 1) {
        this.search();
      }
      this.spinner.hide();
    });

    sender.closeRow(rowIndex);
  }

  // supprimer Companies
  removeHandler() {
    this.companiesService.deleteCompanies(this.dataItem_id).subscribe(r => {
      let resp = r.json();

      if (resp.result == 1) {
        this.modalService.dismissAll();
        this.search();
      }
    });
  }

  //les methodes de model
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.id;
    this.dataItem_name = dataItem.name;
  }

  // fermer la ligne vide pour annuler la l'ajoute
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // recuperer idCompany selected
  addCompaniesURL(item) {
    this.companyIdSelected = item.id;
    this.showCompaniesList = false;
  }

  // afficher ou non la liste des compnay id
  backToList() {
    this.showCompaniesList = true;
  }

  // pour checker si le checkbox est checke
  isChecked(dataItem) {
    var boolresult = false;

    if (dataItem.active == 1 || dataItem.active == '1') {
      boolresult = true;
    }

    return boolresult;
  }
}
