import { GridDataResult } from '@progress/kendo-angular-grid';
import { Component, OnInit } from '@angular/core';
import { QuestionnaireVersionsService, UsersService } from '../../services/services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuestionnaireVersion } from '../../models/questionnaireVersion';
import { State } from '@progress/kendo-data-query';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-questionnaire-versions',
  templateUrl: './questionnaire-versions.component.html',
  styleUrls: ['./questionnaire-versions.component.scss']
})

export class QuestionnaireVersionsComponent implements OnInit {
  //déclaration et initialisation des variables
  public questionnaire_id = 0;
  public selectedValueAxe_id = 0;
  public formGroup: FormGroup;
  public editedRowIndex: number;
  public dataItem_name: string = '';
  public dataItem_id: string = '';
  public dataItemDescription = '';
  public questionnaire_version_id = '';
  public questionnaireVerId = '';
  public question_id = '';
  public valueEndDate: Date = new Date();
  public valueStartDate: Date = new Date();
  public qnrVer: QuestionnaireVersion;
  public gridView: GridDataResult;
  public gridState: State = {
    sort: [{ 'field': 'description', 'dir': 'asc' }],
    skip: 0,
    take: 10
  };

  constructor(
    private quesionnaireVersion: QuestionnaireVersionsService,
    private router: Router, private modalService: NgbModal,
    private usersService: UsersService,
    private route: ActivatedRoute, private spinner: NgxSpinnerService) {
    if (sessionStorage.getItem('admKYU') == '1') {
      this.router.navigate(['/users']);
    }
    // initialisation des variables
    this.qnrVer = new QuestionnaireVersion();
  }

  ngOnInit() {
    //on point en haut de la page
    window.scroll(0, 0);

    // on récupère le paramètre passé
    this.route.params.subscribe(params => {
      this.questionnaire_id = params['idQuestionnaire'];
      this.questionnaireVerId = params['idQuestionnaireVersion'];
      this.question_id = params['idQuestion'];

    });
    this.search();
    this.getAllQuetionnaireVersions();
  }

  //pour modifier une ligne
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    // formater la date
    this.valueEndDate = new Date(dataItem.end_date.split('-')[0], dataItem.end_date.split('-')[1] - 1, dataItem.end_date.split('-')[2].split(' ')[0]);
    this.valueStartDate = new Date(dataItem.start_date.split('-')[0], dataItem.start_date.split('-')[1] - 1, dataItem.start_date.split('-')[2].split(' ')[0]);

    //garder la version active 
    this.qnrVer.active = dataItem.active;

    this.formGroup = new FormGroup({
      questionnaireVersionId: new FormControl(dataItem.id),
      description: new FormControl(dataItem.description, Validators.required),
      questionnaire_id: new FormControl(this.questionnaire_id),
      end_date: new FormControl(this.valueEndDate, Validators.required),
      start_date: new FormControl(this.valueStartDate, Validators.required),
    });

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  // change data gridState
  public onStateChange(state: State) {
    this.gridState = state;
    this.search();
  }

  // on disactive toutes les questionnaires version puis on active le questionnare version qui l'id
  enable(dataItem_id: any) {
    this.quesionnaireVersion.anableQuestionnaireVersion(dataItem_id).subscribe(r => {

      if (r.json() == '1') {
        this.getAllQuetionnaireVersions();
      }
    });
  }

  // von duplique toutes les axes ,domaines, questions, reponses
  duplicate() {
    this.spinner.show();
    this.quesionnaireVersion.duplicateQuestionnaireVersions(this.questionnaire_id, this.questionnaire_version_id).subscribe(r => {
      if (r.json() == 1) {
        this.getAllQuetionnaireVersions();
        this.spinner.hide();
      }
    });

    this.modalService.dismissAll();
  }

  // pour avoir touts les questions version
  getAllQuetionnaireVersions() {
    this.quesionnaireVersion.getAllQuetionnaireVersions(this.questionnaire_id, this.gridState).subscribe(r => {
      this.gridView = r.json();
    });
  }

  // aller au grid axe
  goToAxe(questionnaire_version_id) {
    this.router.navigate(['/questionnaires/versions/axe-versions/', this.questionnaire_id, questionnaire_version_id]);
  }

  // aller au grid question
  goToQuestion(questionnaire_version_id) {
    this.router.navigate(['/questionnaires/versions/questions/', this.questionnaire_id, questionnaire_version_id]);
  }

  //pour la fermeture d'une ligne
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // pour la sauvegarde
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {

    // affecte la nouvel date
    formGroup.value.end_date = this.valueEndDate;
    formGroup.value.start_date = this.valueStartDate;

    this.quesionnaireVersion.save(formGroup, this.qnrVer.active).subscribe(r => {
      const resp = r.json();
    });

    this.getAllQuetionnaireVersions();
    sender.closeRow(rowIndex);
  }

  // pour annuller la modification ou l'ajout d'une ligne
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  // pour ajouter une ligne
  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({
      questionnaireVersionId: new FormControl(this.questionnaire_version_id),
      description: new FormControl('', Validators.required),
      questionnaire_id: new FormControl(this.questionnaire_id),
      end_date: new FormControl(this.valueEndDate, Validators.required),
      start_date: new FormControl(this.valueStartDate, Validators.required)
    });

    sender.addRow(this.formGroup);
  }

  //pour supprimer un questionnaire version
  deleteQuestionnaireVersion() {
    this.quesionnaireVersion.deleteQuestionnaireVersion(this.dataItem_id).subscribe(r => {
      const resp = r.json();
      if (resp == 1) {
        this.modalService.dismissAll();
        this.getAllQuetionnaireVersions();
      }
    });
  }

  // les methodes de model pour la suppression
  openVerticallyCentered(content, dataItem) {
    this.modalService.open(content, { centered: true });
    this.dataItem_id = dataItem.id;
    this.dataItem_name = dataItem.description;
  }

  // les methodes de model pour la duplication
  openVerticallyCenteredDuplication(contentDuplicate, dataItem) {
    this.modalService.open(contentDuplicate, { centered: true });
    this.dataItemDescription = dataItem.description;
    this.questionnaire_version_id = dataItem.id;
  }

  // pour la recherche
  search() {
    this.spinner.show();
    this.quesionnaireVersion.searchQuetionnaireVersions(this.questionnaire_id, this.qnrVer, this.gridState).subscribe(r => {
      const resp = r.json();
      this.gridView = {
        data: resp.data,
        total: resp.total
      };
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();

    });
  }

  //routourner ver questionnaire
  goQuestionnaire() {
    this.router.navigate(['/questionnaires']);
  }

  //pour exporter le résultat des utilisateurs Fo 
  exportUserResult(dataItem) {
    this.spinner.show();
    this.usersService.exportUserResults(dataItem.id).subscribe(r => {
      const data = r;
      let date = new Date().toISOString().slice(0, 10);

      let heureMS = new Date().toISOString().slice(11, 19).replace('_', ':');

      const fileName = 'Resultats-' + date + '-' + heureMS + '.xlsx';
      var file = new Blob([r.blob()], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet    ; charset=utf-8"
      });

      FileSaver.saveAs(file, "Resultats-export.xlsx");
      this.spinner.hide();
    });
    
  }
}
